import posthog from 'posthog-js';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import { useRouter } from 'next/router';
import { openModal } from '@/components/BaseModal';
import { Modals } from '@/types/modals';
import { useUserStore } from '@/stores/user';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useAppStore } from '@/stores/app';

import ExploreIcon from '@/assets/icons/explore-icon.svg';
import CreateIcon from '@/assets/icons/create-icon.svg';
import GenerateIcon from '@/assets/icons/generate-icon.svg';
import PremiumIcon from '@/assets/icons/premium-icon.svg';
import ChatIcon from '@/assets/icons/message-circle-icon.svg';

type Props = {
  className?: string;
}

export function MobileBottomMenu({ className }: Props) {
  const { push, pathname } = useRouter();
  const { userStats } = useUserStore();
  const isSignedIn = useIsAuthenticated();
  const { setSignInUiType, setIsSignInModalVisible } = useAppStore(s => s);

  const onSignInRequired = () => {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    
    posthog?.capture('signin_display_mobile_bottom_menu_premium');
  }

  const handlePremiumClick = () => {
    if (isSignedIn) {
      if (userStats.subscription && userStats.subscription.status === 'active') {
        push('/store');
      } else {
        push('/premium');
      }
    } else {
      onSignInRequired();
    }
  }

  const isActive = (path: string) => {
    if (path === '/') {
      return pathname === '/';
    }
    return pathname.startsWith(path);
  };

  return (
    <div className={cn("grid grid-cols-5 px-3 items-center md:hidden w-full h-[60px] fixed z-[20] bottom-0 border-t border-white border-opacity-10 bg-black-200", className)}>
      <button 
        onClick={() => {
          if (!isActive('/')) {
            push('/');
          }
        }} 
        aria-label="Explore"
      >
        <MenuButton icon={ExploreIcon} text="Explore" isActive={isActive('/')} />
      </button>

      <button 
        onClick={() => {
          if (!isActive('/images/generate')) {
            if (isSignedIn) {
              push('/images/generate');
            } else {
              onSignInRequired();
            }
          }
        }} 
        aria-label="Generate"
      >
        <MenuButton icon={GenerateIcon} text="Generate" isActive={isActive('/images/generate')} />
      </button>

      <button 
        onClick={() => {
          if (!isActive('/v2/character/create')) {
            push('/v2/character/create');
          }
        }} 
        aria-label="Create"
      >
        <MenuButton icon={CreateIcon} text="Create" isActive={isActive('/v2/character/create')} />
      </button>

      <button 
        onClick={() => {
          if (!isActive('/chat')) {
            push('/chat');
          }
        }} 
        aria-label="Chat"
      >
        <MenuButton icon={ChatIcon} text="Chat" isActive={isActive('/chat')} />
      </button>

      {(userStats && userStats.subscription && userStats.subscription.status === 'active') ? (
        <button 
          onClick={() => {
            if (!isActive('/store')) {
              handlePremiumClick();
            }
          }} 
          aria-label="Store"
        >
          <MenuButton icon={PremiumIcon} text="Gems" isActive={isActive('/store')} highlighted={true}/>
        </button>
      ) : (
        <button 
          onClick={() => {
            if (!isActive('/premium')) {
              handlePremiumClick();
            }
          }} 
          aria-label="Premium"
        >
          <MenuButton icon={PremiumIcon} text="Premium" isActive={isActive('/premium')}/>
        </button>
      )}

    </div>
  );
}

type MenuButtonProps = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string;
  isActive: boolean;
  highlighted?: boolean;
}

function MenuButton({ icon: Icon, text, isActive, highlighted }: MenuButtonProps) {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex w-6 h-6 rounded-sm">
        <Icon className={cn("w-full h-full", isActive ? "text-pink" : highlighted ? "text-blue" : "text-white/70")} />
      </div>
      <Text className={cn('text-xs leading-3 mt-1', isActive ? 'text-pink' : 'text-white/70')}>{text}</Text>
    </div>
  );
}
import React from 'react';
import { SocialMediaButton } from '@/components/buttons/SocialMediaButton';
import useIsMobile from '@/hooks/useIsMobile';
import { useRouter } from 'next/router';
import { useAppStore } from '@/stores/app';
import discordIcon from '@/assets/social-icons/discord-icon.svg';
import emailIcon from '@/assets/social-icons/email-icon.svg';
import { CustomLink } from '@/components/CustomLink';

const SidebarSocial: React.FC = () => {
  const isMobile = useIsMobile();
  const toggleSidebar = useAppStore((s) => s.toggleSidebar);
  const { push } = useRouter();

  const handleNavClick = (path: string) => {
    if (isMobile) {
      toggleSidebar();
    }
    push(path);
  };

  return (
    <div className="flex flex-col px-5 md:px-8 pt-4 pb-3 border-t border-black-200">
      <div className="flex justify-center mb-4 lg:justify-start">
        <SocialMediaButton
          className="social-btn mr-3"
          onClick={() => window.open('https://discord.gg/thotbots', '_blank')}
          Icon={discordIcon}
        />
        <SocialMediaButton
          className="social-btn mr-3"
          onClick={() => window.open('mailto:support@thotchat.ai', '_blank')}
          Icon={emailIcon}
        />
      </div>
      <div className="flex justify-between md:-ml-5 md:-mr-5">
        <CustomLink
          href="/legal/privacy-policy"
          title="Privacy Policy"
          onClick={() => handleNavClick('/legal/privacy-policy')}
        >
          Privacy Policy
        </CustomLink>
        <CustomLink
          href="/legal/terms-of-service"
          title="Terms of Service"
          onClick={() => handleNavClick('/legal/terms-of-service')}
        >
          Terms of Service
        </CustomLink>
      </div>
    </div>
  );
};

export default SidebarSocial;

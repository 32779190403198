import Link from 'next/link'
import type { ComponentProps } from 'react'

// Helper function to check if path is allowed based on robots.txt rules
export const isAllowedPath = (path: string): boolean => {
  // Strip protocol and domain
  const normalizedPath = '/' + path
    .replace(/^https?:\/\//, '')
    .replace(new RegExp(`^${process.env.NEXT_PUBLIC_AUTH_URL?.replace(/^https?:\/\//, '')}`), '')
    .replace(/^\/+/, '');

  // List of allowed paths from robots.txt
  const allowedPaths = [
    '/', // Homepage (exact match)
    '/info/',
    '/social/post/',
    '/sitemap.xml',
    '/sitemap'
  ]

  const result = allowedPaths.some(allowedPath => {
    if (allowedPath === '/') {
      // Homepage needs exact match
      return normalizedPath === '/'
    }
    // Path prefix match for others
    return normalizedPath.startsWith(allowedPath)
  })
  return result
}

export const CustomLink = (props: ComponentProps<typeof Link>): JSX.Element => {
  const shouldFollow = isAllowedPath(props.href.toString())
  
  return (
    <Link 
      {...props}
      rel={shouldFollow ? 'follow' : 'nofollow'}
    />
  )
}